import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { navItems } from '../../_nav';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-store';
import * as socketIo from 'socket.io-client';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  public navItems: any[] = [];
  public websocket:socketIo
  Username: string = '';
  Password: string = '';
  message;
  token;
  ipAddress: any;
  constructor(private apiService: ApiService, private route: Router, private cookieService: CookieService, private http: HttpClient, public localStorageService: LocalStorageService) {

  }
  ngOnInit() {
    this.ipAddress = Math.floor((Math.random() * 10000000000000000)).toString();
    this.apiService.getToken(this.ipAddress).subscribe((data: any) => {
      if (data.data.token) {
        this.token = data.data.token;
        this.localStorageService.set("token", this.token);
        this.localStorageService.set('ip', this.ipAddress);
        // this.cookieService.set('token',this.token);
        // this.cookieService.set('ip',this.ipAddress);
        this.localStorageService.set('lang','en');
        this.apiService.setToken(this.token, this.ipAddress);

      }

    })
  }

  loginUser() {

    if (!this.Username || !this.Password) {
      this.message = "Please enter Username and Password";
    }
    else {

      let tokenname = this.localStorageService.get('token');
      if (tokenname) {
        this.apiService.loginUser(this.Username, this.Password).subscribe((data: any) => {
          if (data.data) {
            var obj1 = data.data.partner.partners_roles.find(e => e.role.display_name == "Admin");
            if (obj1) {
              this.apiService.getMenu().subscribe((menu: any) => {
                menu.data.forEach(element => {
                  if(element.name == "Live asset" || element.name == "Live Assets"){
                    console.log("iam here");
                    element.attributes= { disabled: true }
                  }
                  
                });
                this.navItems = menu.data;
                var myJsonString = JSON.stringify(this.navItems);
                var ciphertext = CryptoJS.AES.encrypt(myJsonString, 'pashumart123').toString();
                this.localStorageService.set('menu', ciphertext);
                this.localStorageService.set('role_id', obj1.role_id);
                this.localStorageService.set('partnerId', data.data.partner.id);
                this.localStorageService.set('state_id', data.data.partner.state_id);
                this.localStorageService.set('isLogin', 'true');
                this.localStorageService.set('notificationcount',0);
                var hours = 24;
                var now = new Date().getTime();
                this.localStorageService.set('setuptime',now);
               this.route.navigateByUrl('/dashboard');
              });
            } else {
              this.message = "No access to login"
            }
          }
        }, error => {
          this.message = "Username or Password Invalid";

        });
      }
    }
  }



}