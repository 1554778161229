import { Injectable } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { default as config } from './../config';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  API_URL = config.API_URL;
  animalname: string;
  public token: any;
  public deviceid: any;
  client_id = config.client_id;
  client_secret = config.client_secret;
  langSubject = new Subject<string>();
  // language = "te";
  language$ = this.langSubject.asObservable();

  constructor(private httpClient: HttpClient, private cookieService: CookieService,private route: Router) {
  }
  setToken(token, device) {
    this.token = token;
    this.deviceid = device;
  }
  getToken(device) {
    let headers = new HttpHeaders({ 'device-id': device });
    let body = {
      'client_id': this.client_id,
      'client_secret': this.client_secret
    };
    return this.httpClient.post(`${this.API_URL}/auth/get-token`, body, { headers: headers });
  }
  viewemployee() {
    let headers = new HttpHeaders();
    return this.httpClient.get(`${this.API_URL}/employee`, { headers: headers });
  }
  addrequest(requestdata) {
    let headers = new HttpHeaders();
    return this.httpClient.post(`${this.API_URL}/requestdata`, requestdata, { headers: headers });
  }
  viewrequest() {
    let headers = new HttpHeaders();
    return this.httpClient.get(`${this.API_URL}/request/all`, { headers: headers });
  }


  employeemember(emloyeeid) {
    let headers = new HttpHeaders();
    return this.httpClient.get(`${this.API_URL}/employee/member/` + emloyeeid, { headers: headers });
  }

  viewreportbybreed(breeddata) {
    let headers = new HttpHeaders();
    return this.httpClient.post(`${this.API_URL}/reportby/breed/`, breeddata, { headers: headers });
  }
  viewreportbystate(statedata) {
    let headers = new HttpHeaders();
    return this.httpClient.post(`${this.API_URL}/reportby/state/`, statedata, { headers: headers });
  }
  viewreportbydistrict(districtdata) {
    let headers = new HttpHeaders();
    return this.httpClient.post(`${this.API_URL}/reportby/district/`, districtdata, { headers: headers });
  }
  viewdistrict(statename) {
    let headers = new HttpHeaders();
    return this.httpClient.post(`${this.API_URL}/districts/`, statename, { headers: headers });
  }


  ///////////////////////////////////////////
  getdashboardbreeddata() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/dashboard`, { headers: headers });
  }
  getdashboardstates() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/state`, { headers: headers });
  }
  getdashboardstatedata(state) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/dashboard/state/` + state, { headers: headers });
  }
  getdashboarddistrictdata(district) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/dashboard/district/` + district, { headers: headers });
  }
  getpartners(roleId) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/partner/role`, roleId, { headers: headers });
  }
  getpartnerbyId(id) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/partner/` + id, { headers: headers });
  }
  editpartnerbyId(id, partnerdata) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.put(`${this.API_URL}/partner/` + id +`/edit`, partnerdata, { headers: headers });
  }
  getstate() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/state`, { headers: headers });
  }
  getcitybyId(id) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/cities/all/` + id, { headers: headers });
  }
  getcattles(id, status) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/cattle/partner/` + id + `/` + status, { headers: headers });
  }
  getallcattles() {
    let headers = new HttpHeaders({ 'authorization': this.token,'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/cattle`, { headers: headers });
  }
  getcattlebyid(id) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/cattle/` + id, { headers: headers });
  }
  getcattlehealth(id) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/cattle/` + id + `/health`, { headers: headers });
  }
  getcattletypes(id) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/type/cat/` + id, { headers: headers });
  }
  getcattlecategory() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/category`, { headers: headers });
  }
  getcattlebreeds(id) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/breed/cat/` + id, { headers: headers });
  }
  editcattle(id, cattledata) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.put(`${this.API_URL}/cattle/` + id, cattledata, { headers: headers });
  }
  updateimage(id, imageid, imagedata) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.put(`${this.API_URL}/cattle/` + id + `/image/`, imagedata, { headers: headers });
  }
  deleteimage(id, imageid) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.delete(`${this.API_URL}/cattle/` + id + `/image/` + imageid, { headers: headers });
  }
  getdiseases() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/diseases`, { headers: headers });
  }
  getvaccine() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/vaccine`, { headers: headers });
  }
  edithealth(id, healthid, healthdata) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.put(`${this.API_URL}/cattle/` + id + `/health/` + healthid, healthdata, { headers: headers });
  }
  loginUser(Username: string, Password: string) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    let body = {
      mobile_number: Username,
      password: Password
    };
    return this.httpClient.post(`${this.API_URL}/partner/login`, body, { headers: headers });
  }
  getdistricts(stateid) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/state/` + stateid + `/district`, { headers: headers });
  }
  inserthealth(id, healthdata) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/cattle/` + id + `/health`, healthdata, { headers: headers });
  }
  getroles() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/role`, { headers: headers });
  }
  getdashboardnew() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/dashboard/new`, { headers: headers });
  }
  getMenu() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/menu`, { headers: headers });
  }
  getRequest(rolename, stateId, status) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/request/` + rolename + `/` + stateId + `/` + status, { headers: headers });
  }
  updateRequest(partnerid, requestid, data) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/request/` + partnerid + `/` + requestid, data, { headers: headers });
  }
  getComments(requestid) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/request/` + requestid, { headers: headers });
  }
  getCattle(data) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/request/cattle/details`, data, { headers: headers });
  }
  getAllRequest() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/request`, { headers: headers });
  }
  logout() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.delete(`${this.API_URL}/partner/logout`, { headers: headers });
  }
  getFarms() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/farm`, { headers: headers });
  }
  updateBusiness(id, businessdata) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.put(`${this.API_URL}/partner/business/` + id, businessdata, { headers: headers });
  }
  getSoldCategory() {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/dashboard/sold`, { headers: headers });
  }
  getSoldState(state) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/dashboard/state/` + state + '/sold', { headers: headers });
  }
  getSoldDistrict(district) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/dashboard/district/` + district + '/sold', { headers: headers });
  }
  getAllPartners(){
    let headers = new HttpHeaders({ 'authorization': this.token,'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/partner/register/all`, { headers: headers });
  }
  addCattles(cattledata){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/cattle`, cattledata, { headers: headers });
  }
  updateCattles(cattledata,cattleid){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.put(`${this.API_URL}/cattle/`+ cattleid, cattledata, { headers: headers });
  }
  addImage(cattleid,imagedata){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/cattle/`+cattleid +`/image`, imagedata, { headers: headers });
  }
  getCattlesById(id){
    let headers = new HttpHeaders({ 'authorization': this.token,'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/partner/`+id+`/cattle`, { headers: headers });
  }
  getColors(){
    let headers = new HttpHeaders({ 'authorization': this.token,'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/color`, { headers: headers });
  }
  insertCattleHealth(cattleid){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.get(`${this.API_URL}/cattle/`+cattleid+`/vaccines`, { headers: headers });
  }
  getPartnerCount(){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.get(`${this.API_URL}/dashboard/partner/count`, { headers: headers });
  }
  getCattleByage(data){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/dashboard/search`, data, { headers: headers });
    }
  getFarmers(requestId){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.get(`${this.API_URL}/request/farmers/` + requestId, { headers: headers });
  }
  getTransporters(transporterIds){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/request/transporters`, transporterIds, { headers: headers });
  }
  addLogisticRequest(requestedData){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/request/logistic`, requestedData, { headers: headers });
  }
  getLiveassets(skip,limit){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/live-assets?skip=`+skip+`&limit=`+limit, { headers: headers });

  }
  getAssettransaction(key){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/live-assets/code/`+key, { headers: headers });
  }
  getAnimalgraph(data){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/dashboard/animalgraph`, data, { headers: headers });
  }
  getAnimalsoldgraph(data){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.post(`${this.API_URL}/dashboard/animalsoldgraph`, data, { headers: headers });
  }
  getVeterinarian(requestId){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.get(`${this.API_URL}/request/veterinarian/` + requestId, { headers: headers });
  }
  postVetrinarian(postdata){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.post(`${this.API_URL}/request/veterinarian/` , postdata, { headers: headers });
  }
  getVeterinariansentrequest(requestId){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.get(`${this.API_URL}/request/` + requestId + `/veterinarian/`, { headers: headers });
  }
  updateVeterinariansentrequest(requestId,postdata){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.put(`${this.API_URL}/request/veterinarian/`+requestId , postdata, { headers: headers });
  }
  getRequestByPartner(partner_id,type){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    let data = {'partner_id':partner_id};
    return this.httpClient.post(`${this.API_URL}/request/type/` + type + `/`+partner_id + `/partner`,data, { headers: headers });
  }
  getOffineNotification(){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.get(`${this.API_URL}/request/notification/offline`, { headers: headers });
  }
  updateNotification(){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    let data = {};
    return this.httpClient.put(`${this.API_URL}/request/update/notification`, data, { headers: headers });

  }
  getCattles(data){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    // let data = {};
    return this.httpClient.put(`${this.API_URL}/cattle`, data, { headers: headers });
  }
  getVillages(data){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.get(`${this.API_URL}/district/`+ data +`/villages`, { headers: headers });
  }
  getAllVillages(){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.get(`${this.API_URL}/villages`, { headers: headers });
  }
  getAllCattles(data){
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.post(`${this.API_URL}/cattle/downlaod/total`,data, { headers: headers });
  }
  getDiseasesByanimal(cattle_id) {
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/cattle/`+cattle_id+`/diseases`, { headers: headers });
  }
  postRequest(request_id,data){
    console.log("iam here")
    let headers = new HttpHeaders({'authorization': this.token,'device-id': this.deviceid});
    return this.httpClient.post(`${this.API_URL}/request/`+request_id, data, { headers: headers });
  }
  getDiseasesByAnimal(cattle_id){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/cattle/`+cattle_id+`/addvaccineinfo`, { headers: headers });
  }
  deleteAnimal(animalId){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.delete(`${this.API_URL}/cattle/`+animalId, { headers: headers });
  }
  getInactiveAnimals(){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/cattle/status/inactive`, { headers: headers });
  }
  updateCategory(data,categoryId){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.put(`${this.API_URL}/category/`+categoryId,data,{headers:headers});
  }
  updateBreed(data,breedId){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.put(`${this.API_URL}/breed/`+breedId,data,{headers:headers});
  }
  updateType(data,typeId){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.put(`${this.API_URL}/type/`+typeId,data,{headers:headers});
  }
  getMeat(){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/meat`, { headers: headers });
  }
  getLeather(){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/leather`, { headers: headers });
  }
  getAge(){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/age`, { headers: headers });
  }
  updateMeat(data,id){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.put(`${this.API_URL}/meat/`+id,data,{headers:headers});
  }
  updateLeather(data,id){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.put(`${this.API_URL}/leather/`+id,data,{headers:headers});
  }
  updateAge(data,id){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.put(`${this.API_URL}/age/`+id,data,{headers:headers});
  }
  getAgeByCategory(id){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/age/`+id, { headers: headers });
  }
  getAllLogisticsRequest(){
    let headers = new HttpHeaders({ 'authorization': this.token, 'device-id': this.deviceid });
    return this.httpClient.get(`${this.API_URL}/request/logistic/all`, { headers: headers });
  }
  updateage(data){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.put(`${this.API_URL}/age/`,data,{headers:headers});
  }
  postPrice(data){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.put(`${this.API_URL}/price`,data,{headers:headers});
  }
  getPrice(){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.get(`${this.API_URL}/price`,{headers:headers});
  }
  getInsuranceList(data){
    let headers = new HttpHeaders({'authorization':this.token,'device-id':this.deviceid});
    return this.httpClient.post(`${this.API_URL}/insurance/animal/list`,data,{headers:headers});

  }
}
